/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* hover effect (moving line) for anchor tags*/
  a.special_hover::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #ffae19;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  a.special_hover:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

  /* hover effect (zooming in) for images*/
  img.hoverzoom {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  img.hoverzoom:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  div.buybuttonconc:hover {
    background-color: #ffae1972;
    transition: 0.5s ease-in-out;
  }

  .acqbuttonconcdet:hover {
    color: black;
    background-color: #ffae19;
  }

  img.navbar-logo {
    width: 27%;
  }

  /* hover effect on buy button */
  a.buybutton:hover {
    background-color: #ffae19;
    transition: 0.3s ease-in-out;
  }

  a.info_button:hover {
    background-color: #cacbcc;
    transition: 0.3s ease-in-out;
  }

  /* hover effect on cd title (homepage) */
  div a.special_hover h4 span:hover {
    font-weight: 500;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }

  div.yearselect a:hover {
    font-size: 2.1em;
    color: #090c1e;
    transition: 0.5s ease-in-out;
  }
}

/*uppercase*/

.uppercasebold1 {
  text-transform: uppercase;
  font-weight: 600;
  color: #131313;
}

.uppercasebold2 {
  text-transform: uppercase;
  font-weight: 500;
  color: #131313;
}

.uppercase {
  text-transform: uppercase;
}

/*concert cards*/
div.concertcardfirst,
div.concertcard {
  background-color: #fff;
  padding: 1em;
  border-radius: 5px;
}

div.moreinfo {
  padding: 0.3em 0;
  background-color: #ecf0f8;
  border-radius: 5px;
}

div.moreinfo a mark {
  color: rgb(25, 25, 25);
}

div.buybuttonconc {
  border: solid #ffae19 2px;
  border-radius: 5px;
  padding: 0.3em 0.6em;
}

a.acqbuttonconcdet {
  color: #ffae19;
  font-weight: 500;
  font-size: 1.3em;
  border: solid #ffae19 2.5px;
  border-radius: 5px;
  padding: 0.5em 1em;
}

@media only screen and (max-width: 1200px) {
  a.navbar-brand {
    display: none;
  }

  img.navbar-logo {
    display: none;
  }

  #sponsorhomepage {
    display: none;
  }

  /*controls the height of block on the top of the page on mobile view*/

  .container {
    min-height: 3em;
  }

  div.grd3 {
    min-height: 40vh;
  }

  img.maestropic {
    width: 60%;
    border-radius: 5px;
  }

  div.concertcard {
    margin-top: 4em;
  }
}

@media only screen and (min-width: 993px) {
  li.jstmobile {
    display: none;
  }

  section.hero.welcome {
    height: 100vh;
  }

  img.maestropic {
    width: 80%;
    border-radius: 7px;
  }

  #about {
    margin-bottom: 5em;
  }
}

@media only screen and (max-width: 993px) {
  ul.dropdown-menu {
    background-color: rgb(5, 25, 43);
  }

  section.hero.welcome {
    height: 60vh;
  }

  section.hero {
    height: 40vh;
  }

  div.messagebox {
    display: none;
  }

  a.upcevents {
    margin-top: 1em;
  }

  div.desktop {
    display: none;
  }
}

div.newscard {
  border: lightgray solid 1px;
  padding: 2em;
  border-radius: 7px;
  margin-bottom: 4em;
}

li.jstmobile {
  padding-left: 1em;

  text-align: center;

  font-size: 1.1em;
}

li.jstmobile.nav-link.home {
  font-size: 1em;

  text-align: left;
}

span.cdtitle {
  color: #131313;

  font-weight: 500;
}

a.buybutton {
  border: solid #ffae19 2px;
  border-radius: 4px;
  padding: 0.5em 0.7em;
}

.mousenormal {
  cursor: default;
}

div.yearselect {
  border: #999999 solid 1px;
  text-align: center;
  padding: 3em 0;
  border-radius: 10px;
}

div.yearselect a {
  color: #999999;
  font-size: 2em;
}

.margintop2em {
  margin-top: 2em;
}

h4.newstitle,
h3.newstitle,
h2.newstitle {
  color: #999999;

  font-weight: 600;

  text-align: center;
}

h6.newsdatum {
  color: #999999;
  font-weight: 500;
  text-align: right;
}

h6.compositor {
  color: #999999;
  font-weight: 500;
}

h4.titlenotaligned {
  color: #999999;
  font-weight: 600;
}

div.portfolio-meta div.float-right span {
  color: #ffae19;
}

div.sponsorname {
  text-align: center;
  padding: 0.5em 1.5em;
  font-size: 1.2em;
}

div.sponsorblock {
  margin-bottom: 4em;
}

div.review {
  margin-top: 2em;
}

a.concertcalendarbtn {
  color: #090c1e;
  font-size: 1.3em;
  font-weight: 600;
}

section.concertcalendar .row:first-child {
  margin-top: 0;
}

section.concertcalendar .row {
  margin-top: 4em;
}

div.concertmonth h2.calmon {
  color: #090c1e;
  font-weight: 500;
  text-transform: uppercase;
}

div.thinyborder {
  border: lightgray solid 0.4px;
}

#first-section {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2000' height='600' preserveAspectRatio='none' viewBox='0 0 2000 600'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1020%26quot%3b)' fill='none'%3e%3cpath d='M2185.69 372.9C2035.48 372.75 1887.62 297.9 1589.55 297.9 1291.47 297.9 1303.3 376.18 993.4 372.9 683.51 369.62 577.69-21.81 397.26-41.49' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2302.95 367.15C2164.14 366.98 2027.87 292.15 1752.78 292.15 1477.7 292.15 1495.6 373.47 1202.62 367.15 909.63 360.83 832.02-80.04 652.45-112.01' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2170.94 135.33C1966.73 135.97 1771.94 285.6 1367.99 285.33 964.03 285.06 781.84-37 565.03-43.28' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2194.93 176.24C1991.98 177.16 1802.54 344.97 1400.67 344.24 998.81 343.51 830.03-57.79 606.42-69.98' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2328.19 345.62C2165.29 343.01 2034.98 146.79 1709.26 141.62 1383.54 136.45 1253.15-58.72 1090.33-61.29' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1020'%3e%3crect width='2000' height='600' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

#lastcds {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2000' height='600' preserveAspectRatio='none' viewBox='0 0 2000 600'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1021%26quot%3b)' fill='none'%3e%3cpath d='M-391.78 337.2C-208.07 336.73 48.37 207.55 332.18 211.2 615.99 214.85 556.8 619.54 694.16 668.4' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-218.89 114.47C-71.89 114.62 72.72 189.47 364.32 189.47 655.93 189.47 630.2 103.94 947.53 114.47 1264.86 125 1330.78 645.91 1530.74 691.84' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-170.97 89.48C-25.75 93.44 64.22 288.55 368.48 305.48 672.73 322.41 747.7 641.66 907.92 656.68' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-107.39 513.5C57.9 511.4 207.4 321.34 525.34 321.5 843.28 321.66 981.22 631 1158.07 639.8' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-308.72 321.2C-160.88 321.35-15.44 396.2 277.85 396.2 571.13 396.2 566.29 320.32 864.42 321.2 1162.55 322.08 1287.67 602.7 1450.99 610.42' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1021'%3e%3crect width='2000' height='600' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

#testimonials {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2000' height='800' preserveAspectRatio='none' viewBox='0 0 2000 800'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1027%26quot%3b)' fill='none'%3e%3cpath d='M166.63 944.33C478.07 902.01 649.98 183.11 1164.28 179.3 1678.57 175.49 1903.93 441.08 2161.92 443.3' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M501.14 809.37C687.99 758.64 621.44 243.15 1026.71 240.55 1431.98 237.95 1810.72 431.76 2077.85 432.55' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M287.88 858.61C576.55 809.81 713.48 106.67 1180.42 100.48 1647.37 94.29 1843.86 307.11 2072.97 308.48' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M730.17 892.16C902.77 864.45 985.83 450.23 1268.56 446.01 1551.29 441.79 1537.75 546.01 1806.95 546.01 2076.14 546.01 2208.44 446.43 2345.34 446.01' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3cpath d='M617.14 830.18C839.64 801.52 969.14 293.95 1335.6 292.51 1702.05 291.07 1864.07 537.05 2054.05 540.51' stroke='rgba(51%2c 121%2c 194%2c 0.06)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1027'%3e%3crect width='2000' height='800' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

div.drkorg {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(80, 80, 108, 1) 52%,
    rgba(111, 82, 89, 1) 100%,
    rgba(255, 94, 0, 0.5942577714679622) 100%
  );
}

div.row div h5.colorok,
span.colorok {
  color: #131313;
  font-weight: 500;
}

div.done {
  background-color: #99999918;
}

div.space {
  margin-top: 1em;
}

img.noradius {
  border-radius: 0px;
}

div.event_label {
  position: absolute;
  background-color: #ffae19;
  right: 0;
  top: 4%;
  border-radius: 5px 0 0 5px;
}

div.event_label > div {
  padding: 0.4em 0.5em 0em 2em;
  font-size: 1em;
}

div.event_label > div > h6 {
  color: #090c1e;
  font-weight: 500;
}

/* background image concert */
.hero.concert1505 {
  /*image format 2000x2000 ca. 1:1*/
  background: url(/public/img/5timesThousand.jpg) no-repeat center center scroll;
}

.hero.concert1505.seventyvh{
  height: 70vh;
}

.hero.concert1505.fiftyvh{
  height: 50vh;
}

/*makes darker the background in specific concert pages*/
section.concert div.grd3 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#090c1e+0,000000+100 */
  background: rgb(9, 12, 30); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(9, 12, 30, 1) 0%,
    rgba(0, 0, 0, 0.449) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(9, 12, 30, 1) 0%,
    rgba(0, 0, 0, 0.517) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(9, 12, 30, 0.9) 0%,
    rgba(0, 0, 0, 0.623) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#090c1e', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

/* news blog style */
.blog-grid {
  margin-top: 15px;
  margin-bottom: 15px;
}
.blog-grid .blog-img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.blog-grid .blog-img .date {
  /* Absolute position is not working 
  position: absolute; */
  position: initial;
  background: #10173f; /* original #3a3973*/
  color: #ffffff;
  padding: 8px 15px;
  left: 0;
  top: 10px;
  font-size: 14px;
}
.blog-grid .blog-info {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  background: #ffffff;
  padding: 20px;
  margin: -30px 20px 0;
  position: relative;
}
.blog-grid .blog-info h5 {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 10px;
}
.blog-grid .blog-info h5 a {
  color: #3a3973;
}
.blog-grid .blog-info p {
  margin: 0;
}
.blog-grid .blog-info .btn-bar {
  margin-top: 20px;
}

.px-btn-arrow {
  padding: 0 50px 0 0;
  line-height: 20px;
  position: relative;
  display: inline-block;
  color: #fe4f6c;
  -moz-transition: ease all 0.3s;
  -o-transition: ease all 0.3s;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;
}

.px-btn-arrow .arrow {
  width: 13px;
  height: 2px;
  background: currentColor;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 25px;
  -moz-transition: ease right 0.3s;
  -o-transition: ease right 0.3s;
  -webkit-transition: ease right 0.3s;
  transition: ease right 0.3s;
}

.px-btn-arrow .arrow:after {
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  display: inline-block;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.footer-mail {
  color: rgba(255, 255, 255, 0.5);
}
.footer-mail:hover {
  color: #fff;
}

.newscardhover {
  transition: transform 0.4s, box-shadow 0.4s; /* Add a smooth transition effect for transform and box-shadow */
  padding: 1rem;
}

header.align-bottom {
  display: flex; /* Enable flexbox layout */
  align-items: flex-end; /* Align items to the bottom */
  padding-bottom: 3rem;
}

.newsButton {
  border: 1px solid;
  font-size: 1.2rem;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}

img.rounded_img {
  border-radius: 10px;
}

label.image-label {
  display: block;
  margin-bottom: 2rem;
}

div p.testimonial_quote {
  font-size: 1.1rem;
}

.cds {
  transition: transform 0.4s;
  padding: 1rem;
  margin-right: 15px;
}

/* Accordion Styles */
.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-header {
  padding: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background-color */
}

/* .accordion-header:hover {
  background-color: #eaeaea;
  border-radius: 10px;
} */

.accordion-content {
  padding: 0 1em;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out; /* Smooth transition for max-height */
}

.accordion-item.open .accordion-content {
  max-height: 1000px; /* Set an appropriate max-height value */
}

/* Base styles for the header and navbar */
header {
  position: relative;
  z-index: 10;
}

.navbar-collapse {
  display: none;
}

.navbar-collapse.show {
  display: block;
}

.nav-item.dropdown .dropdown-menu {
  display: none;
  position: static;
  float: none;
  width: auto;
  margin-top: 0;
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="rgba%28255, 255, 255, 0.5%29" stroke-width="2" linecap="round" linejoin="round" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
}

.submenu-toggle {
  background: none;
  border: none;
  color: inherit;
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: left;
}

/* Utility classes to handle visibility on different devices */
.d-none {
  display: none !important;
}

.d-md-block {
  display: block !important;
}

/* Responsive adjustments for mobile view */
@media (max-width: 767px) {
  .navbar-collapse {
    display: none;
  }

  .navbar-collapse.show {
    display: block;
  }

  .reducewidth{
    width: 85%;
  }

  .mmt-5{
    margin-top: 1rem;
  }
}

/* Hide content on mobile devices (up to 767px) */
.hide-on-mobile {
  display: none;
}

/* Display content on desktop devices (768px and up) */
@media (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }

  .cds:hover {
    transform: scale(1.02);
  }

  .newscardhover:hover {
    transform: scale(1.02); /* Scale the element by 5% on hover */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Increase the shadow size and darkness on hover */
  }
}

.narrow {
  white-space: nowrap;
}

.button-download {
  border: 1px solid;
  font-size: 1.2rem;
  padding: 2px 15px; /* Shorthand for padding-top, padding-bottom, padding-left, padding-right */
  border-radius: 20px;
}

h4.header {
  color: #999999;
  font-weight: 600;
  text-transform: capitalize;
}

.boss_list {
  display: flex;
  flex-direction: row;
}

.boss_title {
  color: #131313;
  font-weight: 500;
}

a.info_button {
  border-radius: 4px;
  padding: 0.5em 0.7em;
  background-color: #ecf0f8;
  color: #090c1e;
  font-size: 0.95rem;
}

iframe.map{
  border: 0;
  border-radius: 10px;
}

.news_details_title{
  font-weight: 600;
  font-size: 2rem;
}

.custom-select-year {
  min-width: 500px;
  display: block;
  margin: 10px auto 0 auto;
}

.custom-select-year option {
  font-family: poppins, sans-serif;

}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .custom-select-year {
    min-width: 100%;
    width: 100%;
  }
}

.red{
  color: red;
}

.font1and2rem{
  font-size: 1.2rem;
}

.notfound{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}