/**************************************
    Template Name: Micrology
    Created By: HTML.Design
    https://html.design
**************************************/

/*********** RESPONSIVE ***********/

@media (max-width: 992px) {

    .onmartop {
        margin-top: 30px;
    }
    .mobmar40 {
        margin:30px 0;
    }
    .padleft30 {
        padding-left: 0;
    }
    .section {
        padding:4rem 0;
    }
    .transparent-header {
        background-color: #090c1e !important;
        padding: 10px 10px !important;
        position: relative !important;
    }
    .header .dropdown-menu {
        min-width: auto;
    }
    .navbar-toggler-right {
        top:-5px;
        cursor: pointer;
    }
    .hero h2 {
        font-size: 44px;
    }
    .tagline-v2 h3 {
        font-size: 22px;
        line-height: 28px;
    }
    .tagline-v2 p {
        font-size: 14px;
        line-height: 24px;
    }
    .foot-link ul li {
        font-size: 13px;
        margin-right: 6px;
    }
    .foot-link ul li::after {
        font-size: 11px;
    }
    body, p {
        font-size: 14px;
        line-height: 1.6;
    }

    .insta-box {
        width: 15%;
        margin-bottom: 10px;
        padding:4px;
    }
}

@media (max-width: 768px) {

    .grd7 {
        background-color: #f32e59 !important;
        background-image: none !important; 
    }
    .testimonial_desc:after {
        display: none;
    }
    .testi-style-01 img {
        float: none !important;
        margin-bottom: 20px;
    }
    .mobmar30 {
        margin:30px 0;
    }

    .mobmartop30 {
        margin-top: 30px;
    }
    .mega-menu-items {
        padding:1rem 2rem !important; 
    }
    .hero h2 {
        font-size: 34px;
    }
}

@media (min-width: 992px) {
    .header .dropdown-menu {
        margin-top: 13px;
    }
    .header .navbar-nav .has-submenu {
        position: static;
    }
    .header .navbar-nav .has-submenu:hover > ul {
        visibility: visible;
        opacity: 1;
        top: 100%;
    }
    .header .navbar-nav .has-submenu:hover > ul li ul {
        margin-top: -1px;
    }

    .header .navbar-nav .has-submenu ul {
        -webkit-transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out, top 0.35s ease-in-out, bottom 0.35s ease-in-out, left 0.35s ease-in-out, right 0.35s ease-in-out;
        -moz-transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out, top 0.35s ease-in-out, bottom 0.35s ease-in-out, left 0.35s ease-in-out, right 0.35s ease-in-out;
        -o-transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out, top 0.35s ease-in-out, bottom 0.35s ease-in-out, left 0.35s ease-in-out, right 0.35s ease-in-out;
        transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out, top 0.35s ease-in-out, bottom 0.35s ease-in-out, left 0.35s ease-in-out, right 0.35s ease-in-out;
    }
    .header .navbar-nav .has-submenu .dropdown-menu {
        padding: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    .header .navbar-nav .has-submenu > ul {
        position: absolute;
        top: 130%;
        visibility: hidden;
        display: block;
        opacity: 0;
        left: auto;
    }
    .header .navbar-nav .has-submenu > ul li {
        position: relative;
    }
    .header .navbar-nav .has-submenu > ul li:hover > ul {
        visibility: visible;
        opacity: 1;
        left: 100%;
    }

    .header .navbar-nav .has-submenu > ul li ul {
        position: absolute;
        top: 0;
        visibility: hidden;
        display: block;
        opacity: 0;
        left: 130%;
    }
    .header .navbar-nav .has-submenu > ul li ul.show-right {
        right: 130%;
        left: auto;
    }
}



